body {
    margin: 0;
    font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;

    header {
        background-color: #007bff;

        nav {
            a {
                display: inline-block;
                color: white;
                text-decoration: none;
                font-size: 1.5em;
                padding: .5em;
            }
        }
    }
    #forkme {
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
    }
    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        x-app {
            display: block;
            margin: 1em;

            a.card {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.25);
                transition: box-shadow .2s;
                text-decoration: none;

                > * {
                    margin: .5em;
                }
                img {
                    max-height: 10vmin;
                }
                h3 {
                    min-width: 8em;
                    color: black;
                }
                &:hover {
                    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.25);
                }
            }
        }
    }
    footer {
        text-align: center;
    }
}
